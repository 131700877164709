const state = {
    chats: [],
    workDay: null,
    from: null,
}

const getters = {
    chats: (state) => {
        return state.chats;
    },
    workDay: (state) => {
        return state.workDay;
    },
    from: (state) => {
        return state.from
    },
}

const actions = {
    openChat({commit}, {chats, workDay, from}) {
        commit("setChats", chats);
        commit("setWorkDay", workDay);
        commit("setFrom", from);
    },
}

const mutations = {
    setChats(state, data) {
        state.chats = data;
    },
    setWorkDay(state, data) {
        state.workDay = data;
    },
    setFrom(state, data) {
        state.from = data;
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
  };