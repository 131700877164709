<template>
  <div class="container-fluid h-100">
    <div class="card">
      <div class="card-header">
        <div class="row">
          <div class="col">
            <button class="btn" @click="toggleGlobalHideEnded">
              <font-awesome-icon title="Hide All Ended Routes" icon="eye" v-show="!globalHideEnded" />
              <font-awesome-icon title="Show All Ended Routes" icon="eye-slash" v-show="globalHideEnded" />
            </button>
            &nbsp; Routes for
            <select v-model="workDay" :options="availableWorkDays">
              <option v-for="d in availableWorkDays" :key="d">{{ d }}</option>
            </select>
            &nbsp;
            <b-button @click="doChat">
              <font-awesome-layers>
                <font-awesome-icon size="lg" icon="comment" />
                <font-awesome-layers-text :class="chatCounterClass" counter :value="unreadChats" class="fa-2x" position="top-right" />
              </font-awesome-layers>
            </b-button>
          </div>
        </div>
      </div>
    </div>
    <div v-show="errorMessage" class="alert alert-danger">
      {{ errorMessage }}
    </div>
    <div class="card">
      <div class="card-body" v-bind:style="{ 'max-height': contentHeight + 'px' }"
        style="overflow-y: auto; padding: 0px" v-if="filteredWorkDay != null">
        <WorkDayPanelForDriver class="row-striped" :workDay="filteredWorkDay" />
      </div>
    </div>
    <LoadingSplash />
    <LadingShipmentModal />
    <ChatModal />
  </div>
</template>

<script>
import LoadingSplash from "../LoadingSplash.vue";
import LadingShipmentModal from "./LadingShipmentModal.vue";
import { mapGetters, mapActions } from "vuex";
import messaging from "../../messaging";
import dateutil from "../../dateutil";
import WorkDayPanelForDriver from "./WorkDayPanelForDriver.vue";
import chat from "../../chat";
import { EventBus } from "@/eventbus";
import ChatModal from "../common/ChatModal.vue";

export default {
  components: {
    LoadingSplash,
    WorkDayPanelForDriver,
    LadingShipmentModal,
    ChatModal,
  },
  data() {
    return {
      workDay: null,
      windowHeight: window.innerHeight || 0,
      driverToBeginRoutes: null,
      driverFilter: null,
      timerHandle: null,
      chats: [],
      chatLastSeen: null,
      chatCounterClass: "chatAllRead",
    };
  },
  computed: {
    ...mapGetters("security", ["userName"]),
    ...mapGetters("common", ["errorMessage", "loading"]),
    ...mapGetters("routes", [
      "getDrivers",
      "driversWithWorkDay",
      "driversWithoutWorkDay",
      "workDays",
      "routes",
      "routesForDriver",
      "readOnly",
      "globalHideEnded",
      "driver",
      "workDayForDriver",
    ]),
    unreadChats() {
      let n = 0;
      if(this.chatLastSeen) {
        n = this.chats.filter(c => c.ts > this.chatLastSeen).length;
      } else {
        n = this.chats.length;
      }
      return n;
    },
    contentHeight() {
      return this.windowHeight - 180 || 0;
    },
    availableWorkDays() {
      let d = [];
      for (let i = 0; i < 2; i++) {
        let tmp = dateutil.localNow().subtract(i, "days").format("YYYY-MM-DD");
        d.push(tmp);
      }
      return d;
    },
    filteredWorkDay() {
      return this.workDays.length > 0 ? this.workDays[0] : null;
    },
  },
  watch: {
    driverToBeginRoutes(newVal) {
      if (newVal != null) {
        this.beginWorkDayForDriver(newVal).then(
          () => (this.driverToBeginRoutes = null),
        );
      }
    },
    workDay(newVal) {
      if (newVal != null) {
        this.setWorkDay({ workDay: newVal, driver: null });
      }
    },
  },
  methods: {
    ...mapActions("routes", [
      "loadInitialData",
      "clearData",
      "handleDriverWorkDayRouteMutation",
      "handleDriverWorkDayMutation",
      "handleDriverMutation",
      "handleTrailerMutations",
      "beginWorkDayForDriver",
      "setWorkDay",
      "loadLookups",
      "toggleGlobalHideEnded",
      "setDriver",
      "updateRoutes",
    ]),
    ...mapActions("chat", ["openChat"]),
    clearDriverFilter() {
      this.driverFilter = null;
    },
    onResize() {
      this.windowHeight = window.innerHeight;
    },
    async doStart() {
      await this.clearData();
      await this.setDriver(this.userName);
      messaging.subscribe(
        "DriverDispatchPage.DriverMutations",
        "/topic/Topic.DriverMutations",
        this.handleDriverMutation,
      );
      messaging.subscribe(
        "DriverDispatchPage.DriverWorkDayMutations",
        "/topic/Topic.DriverWorkDayMutations",
        this.handleDriverWorkDayMutation,
      );
      messaging.subscribe(
        "DriverDispatchPage.DriverWorkDayRouteMutations",
        "/topic/Topic.DriverWorkDayRouteMutations",
        this.handleDriverWorkDayRouteMutation,
      );
      messaging.subscribe(
        "DriverDispatchPage.TrailerMutations",
        "/topic/Topic.TrailerMutations",
        this.handleTrailerMutations,
      );
      messaging.restart();
      this.workDay = dateutil.localNowFormatted("YYYY-MM-DD");
    },
    onTick() {
      this.updateRoutes();
      this.updateChatCounterClass();
    },
    updateChatCounterClass() {
      if(this.unreadChats > 0) {
        this.chatCounterClass = this.chatCounterClass == "chatAllRead" ? "chatUnread" : "chatAllRead"
      } else {
        this.chatCounterClass = "chatAllRead";
      }
    },
    doChat() {
      this.openChat({ chats: this.chats, workDay: this.workDayForDriver(this.driver.id), from: this.userName });
      this.$bvModal.show("chat-modal");
    },
    onChatClosed(msg) {
      if (msg.modal == "chat-modal" && msg.data.workDay.id == this.workDayForDriver(this.driver.id).id) {
        this.updateChatLastSeen()
      }
    },
    updateChatLastSeen() {
      let tmp = this.$cookies.get(`chatLastSeen-${this.userName}`);
      if(tmp == null) {
        let d = new Date(new Date().setDate(new Date().getDate() - 1));
        tmp = d.toISOString();
      }
      this.chatLastSeen = tmp;
    }
  },
  mounted() {
    this.updateChatLastSeen();
    window.addEventListener("resize", this.onResize);
    this.loadLookups().then(() => {
      this.doStart();
      this.timerHandle = window.setInterval(this.onTick, 1000);
    });
    chat.chatClient.on("on-chat", msg => {
      if (this.chats?.find(c => c.id == msg.id) == null) {
        this.chats.unshift(msg);
        if (msg.from != this.userName) {
          this.$toasted.show(
            `Chat: ${msg.from.substring(0, msg.from.indexOf("@"))} - ${msg.chat}`,
            {
              type: "info",
              duration: null,
              action: {
                text: 'Dismiss',
                onClick: (e, toastObject) => {
                  toastObject.goAway(0);
                }
              },
            }
          );
        }
      }
    })
    chat.chatClient.on("room-chats", chats => {
      this.chats = chats;
    })
    window.setTimeout(() => {
      chat.chatClient.emit("request-chats", this.userName);
    }, 1000);
    EventBus.$on("modal-hidden", this.onChatClosed);
  },
  beforeDestroy() {
    EventBus.$off("modal-hidden", this.onChatClosed);
    window.clearInterval(this.timerHandle);
    window.removeEventListener("resize", this.onResize);
  },
  destroyed() {
    messaging.unsubscribe("DriverDispatchPage.TrailerMutations");
    messaging.unsubscribe("DriverDispatchPage.DriverWorkDayRouteMutations");
    messaging.unsubscribe("DriverDispatchPage.DriverWorkDayMutations");
    messaging.unsubscribe("DriverDispatchPage.DriverMutations");
  },
};
</script>

<style scoped>
.btn {
  background-color: lightgrey;
}

.btn:hover {
  background-color: lightgreen;
}

.row-striped:nth-of-type(odd) {
  background-color: darkcyan;
}

.row-striped:nth-of-type(even) {
  background-color: lightsalmon;
}

.row-striped {
  border: 3px solid black;
}

.chatAllRead {
  background-color: red;
  color: white;
}

.chatUnread {
  background-color: green;
  color: white;
}
</style>
