import store from "./store";

const { io } = require("socket.io-client");

var chatUrl = null;

try {
  // eslint-disable-next-line no-undef
  chatUrl = CHAT_URL;
} catch (e) {
  chatUrl = "http://rtmessaging.home";
}

export default {
    connected: false,
    chatClient: null,
    connect: function() {
        if( !this.chatClient ) {
            //console.log("chat client not initialized, initializing...");
            this.chatClient = io(chatUrl, {
                query: {
                    userId: `${store.getters["security/userName"]}`,
                    firstName: `${store.getters["security/firstName"]}`,
                    lastName: `${store.getters["security/lastName"]}`,
                    roles: `${store.getters["security/roles"]}`,
                }
            });
            this.chatClient.on("connect", () => {
                this.connected = true;
            });
            this.chatClient.on("disconnect", () => {
                this.connected = false;
            });
        } else {
            //console.log("chat client initialized, reconnecting...");
            this.chatClient.disconnect();
            this.chatClient.connect();
        }
    },
    isStarted: function() {
        return this.connected;
    }
}